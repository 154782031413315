import axios, {
    AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import { ACCESS_TOKEN } from '~/constants/tokens';
import { backendUrl } from '~/helpers/backend';
import { useAuthenticationStore } from '~/store/authentication';

const authorizedApiClient: AxiosInstance = axios.create({
    baseURL: backendUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

// TODO: remove any and fix config
// eslint-disable-next-line @typescript-eslint/no-explicit-any
authorizedApiClient.interceptors.request.use((config): any => {
    const accessToken: string | null = localStorage.getItem(ACCESS_TOKEN);
    const enrichedConfig: AxiosRequestConfig = config;
    // TODO: PLEASE DELETE AGAIN AFTER PHOENIX FIX
    enrichedConfig.params = { ...enrichedConfig.params, noredirect: true };
    if (!enrichedConfig.headers) enrichedConfig.headers = {};
    enrichedConfig.headers['Nx-Token'] = `${accessToken}`;
    return enrichedConfig;
}, (error: AxiosError) => Promise.reject(error));

authorizedApiClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        const isUnauthorized: boolean | undefined = error.response && error.response.status === 401;

        if (isUnauthorized) {
            const authenticationStore = useAuthenticationStore();
            authenticationStore.resetUser();
        }

        return Promise.reject(error);
    },
);
export default authorizedApiClient;
